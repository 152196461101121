@import "../../_globalColor";

.achievement-cards-div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem 1rem;
}

.achievement-heading {
  font-size: 56px;
  font-weight: 400;
  line-height: normal;
  margin: 0px;
}
.achievement-subtitle {
  text-transform: uppercase;
  margin: 0px;
  margin-bottom: 50px;
}
.subTitle {
  color: $subTitle;
}

#achievements > * {
  flex: 1;
}


/* Media Query */
@media (max-width: 1380px) {
  .achievement-heading {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .achievement-heading {
    font-size: 30px;
    text-align: center;
  }
  .achievement-subtitle {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
}
